<template>
  <div>
    <div class="row">
      <h4 class="green">Test Credit Sales</h4>
      <div class="col-12">
        <table>
          <thead>
            <tr>
              <td
                v-for="column in columns"
                :key="column.name"
                @click="column.sortable === true ? sortBy(column.name) : ''"
                :class="
                  sortKey === column.name
                    ? sortOrders[column.name] > 0
                      ? 'headerSortUp'
                      : 'headerSortDown'
                    : ''
                "
                :style="'width:' + column.width + ';' + 'cursor:pointer;'"
              >
                {{ column.label }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tblrows" :key="row.id">
              <td>{{ row.business }}</td>
              <td>{{ row.author }}</td>
              <td>{{ row.credits }}</td>
              <td>{{ TimeParse(row.created_at) }}</td>
              <td>{{ PaymentStatus(row.payment_status) }}</td>
            </tr>
            <tr v-if="tblrows.length < 1">
              <td colspan="5">No Sales Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        Displaying {{ count }} of {{ total_filter }}. Total of {{ total }} rows.
      </div>
      <div class="col-6">
        <div class="btn-group float-right">
          <div class="btn btn-green" v-on:click="GetDataPrev">
            &larr; PREVIOUS
          </div>
          <div class="btn btn-green" v-on:click="GetDataNext">NEXT &rarr;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Organisation_CreditReceiptsDatatable",
  setup() {
    const store = useStore();

    return { store };
  },
  props: {
    Organisation: Number,
  },
  data() {
    let sortOrders = {};
    let columns = [
      {
        width: "auto",
        label: "Business",
        name: "businesses_creditorders.business_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Authorised User",
        name: "businesses_creditorders.author",
        sortable: true,
      },
      {
        width: "auto",
        label: "Credits",
        name: "businesses_creditorders.credits",
        sortable: true,
      },
      {
        width: "auto",
        label: "Created At",
        name: "businesses_creditorders.created_at",
        sortable: true,
      },
      {
        width: "auto",
        label: "Payment Status",
        name: "businesses_creditorders.payment_status",
        sortable: false,
      },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      tblrows: [],
      count: 0,
      order: [3, "DESC"],
      search: "",
      offset: 0,
      total: 0,
      limit: 20,
      total_filter: 0,
      next: 0,
      prev: 0,
      columns: columns,
      sortKey: "businesses_creditorders.created_at",
      sortOrders: sortOrders,
    };
  },
  methods: {
    GetData() {
      let body = {
        order: this.order,
        limit: this.limit,
        offset: this.offset,
        organisation: this.Organisation,
      };
      this.store
        .dispatch("Organisation/GetBusinessCreditReceiptsDatatable", body)
        .then((result) => {
          this.total = result.total;
          this.total_filter = result.total_filter;
          this.tblrows = result.data;
          this.count = this.offset + this.tblrows.length;
          this.prev =
            this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
          this.next =
            this.offset + this.tblrows.length > this.total_filter
              ? this.total_filter
              : this.offset + this.tblrows.length;
        })
        .catch((err) => {
          console.log(err);
          this.total = 0;
          this.total_filter = 0;
          this.tblrows = [];
          this.count = 0;
          this.prev = 0;
          this.next = 0;
        });
    },
    GetDataSearch() {
      this.tblrows = [];
      this.offset = 0;

      this.GetData();
    },
    GetDataNext() {
      if (this.next != this.total_filter) {
        console.log(this.next);
        this.offset = this.next;
        this.GetData();
      }
    },
    GetDataPrev() {
      if (this.offset !== 0) {
        this.offset = this.prev;
        this.GetData();
      }
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      let name = this.getIndex(this.columns, "name", key);
      let dir = this.sortOrders[key] > 0 ? "ASC" : "DESC";
      this.order = [name, dir];

      this.GetData();
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    PaymentStatus(value) {
      switch (value) {
        case 0:
          return "Pending Invoice";
        case 1:
          return "Invoiced";
        case 2:
          return "Paid";
        case 3:
          return "Refunded";
        default:
          return "N/A";
      }
    },
  },
  mounted() {
    this.GetData();
  },
  watch: {
    Organisation() {
      this.GetData();
    },
  },
};
</script>

<style>
</style>