<template>
  <div v-if="this.$route.name == 'Organisation'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Organisations', route: 'Home' },
        { name: organisation.name, route: 'Home' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <Organisation_ListDatatable :Organisation="this.organisation_id" />
        </div>
        <div class="col-6">
          <table class="table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td colspan="2">Role</td>
              </tr>
            </thead>
            <tbody>
              <tr v-if="this.role > 2">
                <td colspan="1">
                  <label for="adduser.email">Email Address</label>
                  <input
                    id="adduser.email"
                    type="email"
                    class="input"
                    v-model="adduser.email"
                    placeholder="example@example.com"
                  />
                </td>
                <td>
                  <label for="adduser.role">Role</label>
                  <select
                    class="input"
                    v-model="adduser.role"
                    id="adduser.role"
                  >
                    <option value="1">Sales Rep</option>
                    <option value="2">Member</option>
                    <option value="3">Manager</option>
                  </select>
                </td>
                <td colspan="2">
                  <button
                    v-on:click="inviteUserToOrganisation"
                    class="btn btn-green"
                  >
                    Invite User <i class="icon icon-pen"></i>
                  </button>
                </td>
              </tr>
              <tr v-for="(user, index) in users" :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ roleTypes(user.role) }}</td>
                <td v-if="this.role > 2">
                  <div
                    class="btn btn-orange"
                    v-on:click="RemoveUserFromOrganisation(user.id, index)"
                  >
                    <span class="icon icon-delete"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-6" v-if="[1, 3].includes(this.role)">
          <Organisation_CreditReceiptsDatatable
            :Organisation="this.organisation_id"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Navigation from "../components/Navigation.vue";
import Organisation_ListDatatable from "@/components/Organisation.ListDatatable.vue";
import Organisation_CreditReceiptsDatatable from "@/components/Organisation.CreditReceiptsDatatable.vue";

export default {
  name: "Organisation",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      organisation: {},
      role: 0,
      users: [],
      adduser: {
        email: "",
        role: 1,
      },
      messages: [],
      errors: [],
    };
  },
  computed: {
    organisation_id() {
      return parseInt(this.$route.params.id);
    },
  },
  components: {
    Navigation,
    Organisation_ListDatatable,
    Organisation_CreditReceiptsDatatable,
  },
  methods: {
    GetOrganisationInfo() {
      this.store
        .dispatch("Organisation/GetOrganisationInfo", {
          id: this.$route.params.id,
        })
        .then((result) => {
          console.log(result);
          this.organisation = result.data;
          this.role = result.role;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetOrganisationUsers() {
      this.store
        .dispatch("Organisation/GetOrganisationUsers", {
          id: this.$route.params.id,
        })
        .then((result) => {
          this.users = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    roleTypes(role) {
      switch (role) {
        case 1:
          return "Sales Rep";
        case 2:
          return "Member";
        case 3:
          return "Manager";
        default:
          return "N/A";
      }
    },
    inviteUserToOrganisation() {
      this.store
        .dispatch("Organisation/InviteOrganisationUser", {
          id: this.$route.params.id,
          ...this.adduser,
        })
        .then((result) => {
          this.messages = result.messages;
        })
        .catch((err) => {
          this.errors = err.messages;
          console.log(err);
        });
    },
    RemoveUserFromOrganisation(user, index) {
      this.store
        .dispatch("Organisation/RemoveUserFromOrganisation", {
          id: this.$route.params.id,
          bid: this.$route.params.bid,
          user_id: user,
        })
        .then((result) => {
          this.messages = result.messages;
          this.errors = [];
          this.users.splice(index, 1);
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = [];
        });
    },
  },
  mounted() {
    this.GetOrganisationInfo();
    this.GetOrganisationUsers();
  },
  watch: {
    organisation_id() {
      if (this.$route.name == "Organisation") {
        this.GetOrganisationInfo();
        this.GetOrganisationUsers();
      }
    },
  },
};
</script>

<style>
.statbox {
  height: auto;
  width: 100%;
  background: #efefef;
}
</style>