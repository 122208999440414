<template>
  <div>
    <div class="row">
      <div class="col-6">
        <form v-on:submit.prevent="GetDataSearch" class="form">
          <label for="email">Filter businesses.</label>
          <input
            type="text"
            class="input"
            placeholder="Email, Name..."
            v-model="search"
          />
        </form>
      </div>
      <div class="col-6">
        <router-link
          class="btn btn-green float-right"
          :to="{
            name: 'Organisation_Businesses_Create',
            params: { id: Organisation },
          }"
          ><i class="icon icon-pen"></i> Register Business</router-link
        >
      </div>
      <div class="col-12">
        <table>
          <thead>
            <tr>
              <td
                v-for="column in columns"
                :key="column.name"
                @click="column.sortable === true ? sortBy(column.name) : ''"
                :class="
                  sortKey === column.name
                    ? sortOrders[column.name] > 0
                      ? 'headerSortUp'
                      : 'headerSortDown'
                    : ''
                "
                :style="'width:' + column.width + ';' + 'cursor:pointer;'"
              >
                {{ column.label }}
              </td>
              <td><i class="icon icon-cogs"></i></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tblrows" :key="row.id">
              <td>{{ row.name }}</td>
              <td>{{ row.contact_email }}</td>
              <td>{{ row.credits }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'Organisation_Businesses_Modify',
                    params: { id: Organisation, bid: row.id },
                  }"
                  class="btn btn-orange"
                  >Modify</router-link
                >
              </td>
            </tr>
            <tr v-if="tblrows.length < 1">
              <td colspan="4">No Businesses Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        Displaying {{ count }} of {{ total_filter }}. Total of {{ total }} rows.
      </div>
      <div class="col-6">
        <div class="btn-group float-right">
          <div class="btn btn-green" v-on:click="GetDataPrev">
            &larr; PREVIOUS
          </div>
          <div class="btn btn-green" v-on:click="GetDataNext">NEXT &rarr;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Organisation_ListDatatable",
  setup() {
    const store = useStore();

    return { store };
  },
  props: {
    Organisation: Number,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "auto", label: "Name", name: "name", sortable: true },
      {
        width: "auto",
        label: "Contact Email",
        name: "contact_email",
        sortable: true,
      },
      {
        width: "auto",
        label: "Available Tests",
        name: "credits",
        sortable: true,
      },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      tblrows: [],
      count: 0,
      order: [0, "DESC"],
      search: "",
      offset: 0,
      total: 0,
      limit: 20,
      total_filter: 0,
      next: 0,
      prev: 0,
      columns: columns,
      sortKey: "name",
      sortOrders: sortOrders,
    };
  },
  methods: {
    GetData() {
      let body = {
        search: this.search,
        order: this.order,
        limit: this.limit,
        offset: this.offset,
        organisation: this.Organisation,
      };
      this.store
        .dispatch("Organisation/GetBusinessesDatatable", body)
        .then((result) => {
          this.total = result.total;
          this.total_filter = result.total_filter;
          this.tblrows = result.data;
          this.count = this.offset + this.tblrows.length;
          this.prev =
            this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
          this.next =
            this.offset + this.tblrows.length > this.total_filter
              ? this.total_filter
              : this.offset + this.tblrows.length;
        })
        .catch((err) => {
          console.log(err);
          this.total = 0;
          this.total_filter = 0;
          this.tblrows = [];
          this.count = 0;
          this.prev = 0;
          this.next = 0;
        });
    },
    GetDataSearch() {
      this.tblrows = [];
      this.offset = 0;

      this.GetData();
    },
    GetDataNext() {
      if (this.next != this.total_filter) {
        console.log(this.next);
        this.offset = this.next;
        this.GetData();
      }
    },
    GetDataPrev() {
      if (this.offset !== 0) {
        this.offset = this.prev;
        this.GetData();
      }
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      let name = this.getIndex(this.columns, "name", key);
      let dir = this.sortOrders[key] > 0 ? "ASC" : "DESC";
      this.order = [name, dir];

      this.GetData();
    },
  },
  mounted() {
    this.GetData();
  },
  watch: {
    Organisation() {
      this.GetData();
    },
  },
};
</script>

<style>
</style>